import { render, staticRenderFns } from "./ModelScopes.vue?vue&type=template&id=4c941961&scoped=true&"
import script from "./ModelScopes.vue?vue&type=script&lang=ts&"
export * from "./ModelScopes.vue?vue&type=script&lang=ts&"
import style0 from "./ModelScopes.vue?vue&type=style&index=0&id=4c941961&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c941961",
  null
  
)

export default component.exports