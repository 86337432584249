








































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ModelScopesBar from '@/components/review/ModelScopesBar.vue';
import Model from '@/models/Model';
import ModelService from '@/services/ModelService';
import { fixNodeReferencesOfEdges, fixNodeReferencesOfNodeParentId } from '@/serializer/helpers';
import ModelScope from '@/models/ModelScope';
import ModelScopeService from '@/services/ModelScopeService';
import { Action, Getter, State } from 'vuex-class';
import ModelBackgroundImage from '@/models/ModelBackgroundImage';
import ModelConfig from '@/models/ModelConfig';
import ModelConfigService from '@/services/ModelConfigService';
import SelectedModelElement from '@/models/SelectedModelElement';
import Edge from '@/models/Edge';
import Node from '@/models/Node';
import Point from '@/models/Point';
import ReviewCanvas from '@/components/Editor/ReviewCanvas.vue';
import { hasPermission } from '@/auth/AuthService';
import { PossibleAction } from '@/auth/PossibleAction';
import SidebarLeft from '@/components/review/SidebarLeft.vue';
import AttributeBar from '@/components/Editor/AttributeBar.vue';
import CanvasToolbarTop from '@/components/Editor/CanvasToolbar/CanvasToolbarTop.vue';
import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';

@Component({
  components: { CanvasToolbarTop, ModelScopesBar, ReviewCanvas, SidebarLeft, AttributeBar },
  beforeRouteUpdate(to, from, next) {
    if (!(this as ModelScopes).creationProcessActive || !(this as ModelScopes).abortingProcess) {
      window.removeEventListener('beforeunload', (this as ModelScopes).handleBeforeUnload);
      next();
    } else if ((this as ModelScopes).confirmUnsavedChanges()) {
      next();
    } else {
      next(false);
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!(this as ModelScopes).creationProcessActive || !(this as ModelScopes).abortingProcess) {
      window.removeEventListener('beforeunload', (this as ModelScopes).handleBeforeUnload);
      next();
    } else if ((this as ModelScopes).confirmUnsavedChanges()) {
      window.removeEventListener('beforeunload', (this as ModelScopes).handleBeforeUnload);
      next();
    } else {
      next(false);
    }
  },
})
export default class ModelScopes extends mixins(Toasts) {
  protected readonly PossibleAction = PossibleAction;

  protected shouldBeVisible(action: PossibleAction): boolean {
    const permission = hasPermission(action);
    return permission != null && permission;
  }

  protected getState(state: boolean | null, value: string | null, maxLength: number): boolean | null {
    if (value && value.length > 0) {
      const lenCheck = value.length <= maxLength;
      if (!lenCheck) {
        return false;
      }
    }
    return state;
  }

  @Action('setSelectedElements', { namespace: 'modelEditor' })
  protected setSelectedElements!: (selectedElements: SelectedModelElement[]) => void;

  @Action('clearSelectedElements', { namespace: 'modelEditor' })
  protected clearSelectedElements!: () => void;

  @Action('setCurrentModel', { namespace: 'modelEditor' })
  protected setCurrentModel!: (model: Model | undefined) => void;

  @Action('setCurrentConfig', { namespace: 'modelEditor' })
  protected setCurrentConfig!: (config: ModelConfig | undefined) => void;

  @Action('setCurrentModelBackgroundImage', { namespace: 'modelEditor' })
  protected setCurrentModelBackgroundImage!: (backgroundImage: ModelBackgroundImage | undefined) => void;

  @State('selectedElements', { namespace: 'modelEditor' })
  protected selectedElements!: SelectedModelElement[];

  @State('currentModel', { namespace: 'modelEditor' })
  protected currentModel!: Model;

  @Getter('selectedElementIds', { namespace: 'modelEditor' })
  protected selectedElementIds!: string[];

  @Prop({ required: true, default: '' })
  protected readonly projectId!: string;
  @Prop({ required: true, default: '' })
  protected readonly modelId!: string;

  protected modelScopes: ModelScope[] = [];
  protected showLeftBar = true;
  protected selectedScope: ModelScope | null = null;

  protected newScopeDescription = '';
  protected descriptionState: boolean | null = null;
  protected selectedElementsState: boolean | null = null;

  protected abortingProcess = true;
  protected creationProcessActive = false;

  @Watch('modelId')
  public handleChangeModelId(): void {
    this.loadCurrentModel();
  }

  @Watch('selectedElements')
  protected handelSelectionChange(): void {
    this.selectedElementsState = null;
  }

  mounted(): void {
    if (hasPermission(PossibleAction.CAN_GET_SCOPE)) {
      this.$on('finished-delete-scope', (args) => {
        this.modelScopes.splice(this.modelScopes.indexOf(args.scope), 1);
        this.clearSelectedElements();
      });

      window.addEventListener('beforeunload', this.handleBeforeUnload);

      this.clearSelectedElements();
      this.loadCurrentModel();
    } else {
      this.$router.replace({ path: '/' });
    }
  }

  beforeDestroy(): void {
    this.$off('finished-delete-scope');

    this.setCurrentModel(undefined);
    this.setCurrentConfig(undefined);
    this.setCurrentModelBackgroundImage(undefined);
  }

  protected syncLeftSidebarVisibility(visible: boolean): void {
    this.showLeftBar = visible;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  protected handleBeforeUnload(event: any): void {
    if (this.creationProcessActive) {
      event.returnValue = this.confirmUnsavedChangesString;
    }
  }

  protected loadCurrentModel(): void {
    if (hasPermission(PossibleAction.CAN_GET_MODEL)) {
      this.setLoading(true);
      ModelService.getById(this.modelId)
        .then((model) => {
          this.setCurrentModel(fixNodeReferencesOfNodeParentId(fixNodeReferencesOfEdges(model)));
          Vue.nextTick(() => {
            this.loadConfig();
          });
        })
        .catch((backendError) => {
          this.setLoading(false);
          let msg;
          if (backendError.response === undefined) {
            msg = 'Could not find the queried model: ' + this.modelId;
          } else {
            msg = 'Oops, something failed: ' + backendError.response.status;
          }
          this.$router.replace({ name: 'projectDetails', params: { projectId: this.projectId } }).then(() => {
            this.showToast('Failed to load', msg, 'danger');
          });
        });
    }
  }

  protected loadConfig(): void {
    if (hasPermission(PossibleAction.CAN_GET_CONFIG)) {
      ModelConfigService.getByID(this.currentModel.modelConfigId)
        .then((config) => {
          this.setCurrentConfig(config);
          if (this.currentModel && this.currentModel.hasImage) {
            if (hasPermission(PossibleAction.CAN_GET_IMAGE)) {
              this.loadImage()
                .then((imageHref: string) => {
                  this.setCurrentModelBackgroundImage(new ModelBackgroundImage(imageHref, new Point(0, 0)));
                })
                .catch(() => {
                  this.showToast(
                    'Failed to read',
                    'Oops, something went wrong while loading the background image',
                    'danger'
                  );
                })
                .finally(() => {
                  this.loadModelScopes();
                });
            } else {
              this.loadModelScopes();
            }
          } else {
            this.loadModelScopes();
          }
        })
        .catch((backendError) => {
          this.setLoading(false);
          let msg,
            title = 'Failed to load';
          if (backendError.response === undefined) {
            msg =
              'Could not find the config: ' +
              this.currentModel.modelConfig +
              ' ' +
              this.currentModel.modelConfigVersion;
          } else if (backendError.response.status === 403) {
            title = 'Action denied';
            msg = 'You do not have the required rights.';
          } else {
            msg = 'Oops, something failed: ' + backendError.response.status;
          }
          this.$router.replace({ name: 'projectDetails', params: { projectId: this.projectId } }).then(() => {
            this.showToast(title, msg, 'danger');
          });
        });
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  protected loadImage(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (this.currentModel && this.currentModel.id) {
        ModelService.getImageByModelId(this.currentModel.id).then((response) => {
          resolve(response);
        });
      } else {
        reject(false);
      }
    });
  }

  protected loadModelScopes(): void {
    if (hasPermission(PossibleAction.CAN_GET_SCOPE) && this.currentModel.id) {
      ModelScopeService.getAllByModelId(this.currentModel.id)
        .then((modelScopes) => {
          this.showToast('Successfully loaded', modelScopes.length + ' existing model scopes found.', 'info');
          this.modelScopes = modelScopes;
        })
        .catch((backendError) => {
          if (backendError.response.status === 403) {
            this.showToast('Action denied', 'You do not have the required rights.', 'danger');
          } else {
            this.showToast('Failed to load', 'Could not load model scopes.', 'danger');
          }
        })
        .finally(() => this.setLoading(false));
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  protected activateCreationView(): void {
    this.creationProcessActive = true;
    this.selectedScope = null;
    this.newScopeDescription = '';
    this.descriptionState = null;
    this.selectedElementsState = null;
  }

  protected deactivateCreationView(): void {
    this.$bvModal
      .msgBoxConfirm('Do you really want to leave this process?', {
        title: 'Cancel Scope Creation',
        okVariant: 'danger',
        okTitle: 'Leave Process',
        cancelVariant: 'primary',
        cancelTitle: 'Continue',
        hideHeaderClose: true,
        centered: true,
      })
      .then((confirmationResponse) => {
        if (confirmationResponse) {
          this.selectedScope = this.findIdenticalScope();
          this.creationProcessActive = false;
          this.newScopeDescription = '';
          this.descriptionState = null;
          this.selectedElementsState = null;
        }
      })
      .catch(() => {
        this.showToast('Unexpected behaviour', 'Oops, something failed...', 'danger');
      });
  }

  protected selectScope(args: { scope: ModelScope }): void {
    const selectedElements: SelectedModelElement[] = [];
    this.clearSelectedElements();
    this.selectedScope = args.scope;

    Vue.nextTick(() => {
      for (const node of this.currentModel.nodes) {
        if ((this.selectedScope?.nodeIds.indexOf(node.id) !== -1 ?? false) && node.target) {
          selectedElements.push(new SelectedModelElement(node, node.target));
        }
      }

      for (const edge of this.currentModel.edges) {
        if ((this.selectedScope?.edgeIds.indexOf(edge.id) !== -1 ?? false) && edge.target) {
          selectedElements.push(new SelectedModelElement(edge, edge.target));
        }
      }

      for (const drawable of this.currentModel.drawables) {
        if ((this.selectedScope?.drawableIds.indexOf(drawable.id) !== -1 ?? false) && drawable.target) {
          selectedElements.push(new SelectedModelElement(drawable, drawable.target));
        }
      }

      this.setSelectedElements(selectedElements);
    });
  }

  protected createModelScope(): void {
    if (hasPermission(PossibleAction.CAN_SAVE_SCOPE)) {
      this.selectedElementsState = this.selectedElements.length > 0;
      this.descriptionState = this.newScopeDescription.length > 0 && this.newScopeDescription.length <= 5000;
      if (this.selectedElementsState && this.descriptionState) {
        this.setLoading(true);
        const existingScope = this.findIdenticalScope();
        if (existingScope) {
          this.setLoading(false);
          this.selectedElementsState = null;
          this.descriptionState = null;
          this.selectedScope = existingScope;
          this.showToast(
            'Failed to save',
            'There is already a model scope containing exactly these elements.',
            'warning'
          );
        } else if (this.currentModel.id) {
          const scope = new ModelScope(this.currentModel.id);
          scope.description = this.newScopeDescription;
          this.selectedElements.forEach((value) => {
            if (value.modelElement instanceof Node) {
              scope.nodeIds.push(value.modelElement.id);
            } else if (value.modelElement instanceof Edge) {
              scope.edgeIds.push(value.modelElement.id);
            } else {
              scope.drawableIds.push(value.modelElement.id);
            }
          });
          ModelScopeService.save(scope)
            .then((savedScope) => {
              this.modelScopes.push(savedScope);
              this.creationProcessActive = false;
              this.selectedScope = savedScope;
              Vue.nextTick(() => {
                const scopeBar = document.getElementById('scopeBar');
                if (scopeBar) {
                  scopeBar.scrollTop = scopeBar.scrollHeight;
                }
              });
            })
            .catch((backendError) => {
              if (backendError.response.status === 403) {
                this.showToast('Action denied', 'You do not have the required rights.', 'danger');
              } else {
                this.showToast('Failed to save', 'An error occurred while saving the model scope.', 'danger');
              }
            })
            .finally(() => this.setLoading(false));
        }
      } else {
        if (this.newScopeDescription.length > 5000) {
          this.newScopeDescription = 'Description must have a maximum length of 5000 characters.';
          this.descriptionState = false;
        }
      }
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  protected findIdenticalScope(): ModelScope | null {
    let existingScope: ModelScope | null = null;
    for (const scope of this.modelScopes) {
      if (scope.elementCount === this.selectedElements.length) {
        let foundElements = 0;
        this.selectedElements.forEach((selectedElement) => {
          if (
            scope.nodeIds.indexOf(selectedElement.modelElement.id) > -1 ||
            scope.edgeIds.indexOf(selectedElement.modelElement.id) > -1 ||
            scope.drawableIds.indexOf(selectedElement.modelElement.id) > -1
          ) {
            foundElements++;
          }
        });
        if (foundElements === this.selectedElements.length) {
          existingScope = scope;
          break;
        }
      }
    }
    return existingScope;
  }

  protected deleteScope(args: { scope: ModelScope }): void {
    if (PossibleAction.CAN_DELETE_SCOPE) {
      this.$root.$emit('delete-scope', { scope: args.scope, target: this });
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  protected setLoading(loading: boolean): void {
    this.$root.$data.loading = loading;
  }

  protected returnToProject(): void {
    this.$router.push({
      name: 'projectDetails',
      params: { projectId: this.projectId },
    });
  }

  protected selectAllModelScopes(): void {
    const selectedElements: SelectedModelElement[] = [];
    Vue.nextTick(() => {
      for (const modelScope of this.modelScopes) {
        for (const node of this.currentModel.nodes) {
          if (
            modelScope.nodeIds.indexOf(node.id) > -1 &&
            !selectedElements.find((selectedElement) => selectedElement.modelElement.id === node.id) &&
            node.target
          ) {
            selectedElements.push(new SelectedModelElement(node, node.target));
          }
        }

        for (const edge of this.currentModel.edges) {
          if (
            modelScope.edgeIds.indexOf(edge.id) > -1 &&
            !selectedElements.find((selectedElement) => selectedElement.modelElement.id === edge.id) &&
            edge.target
          ) {
            selectedElements.push(new SelectedModelElement(edge, edge.target));
          }
        }

        for (const drawable of this.currentModel.drawables) {
          if (
            modelScope.drawableIds.indexOf(drawable.id) > -1 &&
            !selectedElements.find((selectedElement) => selectedElement.modelElement.id === drawable.id) &&
            drawable.target
          ) {
            selectedElements.push(new SelectedModelElement(drawable, drawable.target));
          }
        }
      }

      this.setSelectedElements(selectedElements);
    });
  }

  protected confirmUnsavedChanges(): boolean {
    return window.confirm(this.confirmUnsavedChangesString);
  }

  get confirmUnsavedChangesString(): string {
    return 'Do you really want to leave the creation?';
  }
}
